import request from "@/services/request";
import baseUrl from "@/services/baseUrl";

// this is for locally used APIs only
let servicePath = "/aa038/v1/mps/";
const mpBaseUrl = baseUrl.main + servicePath;  
export const resetPassword = (params = {}) => request.post(baseUrl.main + '/ba021/v1/org/reset-password', params);
export default {
  // getTopicList: (params = {}) => request.post(baseUrl.main + "/as/index/recommend/v1", params)
  // getMembers: (params = {}) => request.post("http://staging-api.tranzak.me/ba021/v1/org/list", params),
  // addMember: (params = {}) => request.post("http://staging-api.tranzak.me/ba021/v1/org/new", params),
  // updateMember: (params = {}) => request.put("http://staging-api.tranzak.me/ba021/v1/org/user", params),
  // removeMember: (params = null) => request.post("http://staging-api.tranzak.me/ba021/v1/org/delete/"+params, {}),
 
}